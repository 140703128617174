import { defineStore } from 'pinia';
import { Tokens } from '~/utils/constants';
import type { IUserModel, IUserSubscribers } from '~/types/apiService';
import type { TNullable } from '~/types/common';

export const useMainStore = defineStore('main', {
  state: () => ({
    error: '' as unknown,
    notificationUpdateVersion: 0,
    currentToken: Tokens.pearl,
    userData: null as TNullable<IUserModel>,
    userSubscribers: null as TNullable<IUserSubscribers>,
    weatherValue: 0
  }),
  getters: {
    getError: (state) => state.error
  },
  actions: {
    setError(error: unknown) {
      this.error = error;
    },
    updateVersion() {
      this.notificationUpdateVersion++;
    },
    setCurrentToken(token: Tokens) {
      this.currentToken = token;
    },
    setUserData(userData: IUserModel | null) {
      this.userData = userData;
    },
    setUserSubscribersData(userSubscribers: IUserSubscribers) {
      this.userSubscribers = userSubscribers;
    },
    setRandomWeatherValue() {
      const now = new Date();
      const startOfHour = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours());
      const hoursSinceMonthStart = (now.getTime() - startOfHour.getTime()) / (1000 * 60 * 60);
      const randomSeed = hoursSinceMonthStart + now.getFullYear() + now.getMonth();
      const hash = Math.sin(Number(randomSeed.toString().substring(0, 4))).toString();
      this.weatherValue = (parseInt(hash.substring(4, 9)) * 13) % 12;
    }
  }
});
